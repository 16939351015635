import React, { useState, useEffect } from "react";
import '../styles/otherStyles.css';

export default function ImageImport({ imagePath, href }) {
    const [currentImage, setCurrentImage] = useState("");

    useEffect(() => {
        if (imagePath == null) {
            return null;
        }
        import(`../img/${imagePath}`).then((image) => {
            setCurrentImage(image.default);
        });
    }, [imagePath]);

    return (
        <a href={href} target="_blank">
            <img
                className="img-fluid animate"
                style={{ width: "100%", height: "auto" }}
                src={currentImage}
                alt=""
            />
        </a>
    );
}
