import React, { useEffect, useRef, useState, createContext } from "react";
import CardsLayout from "./CardsLayout";
import ConversationLayout from "./ConversationLayout.js";
import responseData from "../responses.json";
import Introduction from "./Introduction";
import { Button, Drawer } from "antd";
import { TbLayoutSidebarLeftCollapse } from "react-icons/tb";
import { TbLayoutSidebarLeftExpand } from "react-icons/tb";
import { FaArrowUp } from "react-icons/fa6";
import '../styles/HomePage.css';
import '../styles/Navigation.css';
import { useNavigate } from "react-router";
import { Input } from "antd";
// import axios from "axios";
// import ReactDom from "react-dom"; 
export const SearchButtonContext = createContext();

export default function HomePage({ modalDisplay, hasVisited, navRequest }) {
    const containerRef = useRef(null);
    function scrollToBottom() {
        containerRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }
    // Referece to the form element
    const formRef = useRef(null);
    // State to store the search content
    const [searchContent, setSearchContent] = useState("");
    const [responseList, setResponseList] = useState([]);
    // State to store the response
    const [response, setResponseState] = useState({
        "question": "",
        "response": ""
    });
    // State to store the navigation state
    const [navState, setNavState] = useState("");
    const [navActive, setNavActive] = useState(() => window.innerWidth >= 768);

    // State to store the search button state
    const [searchButtonState, setSearchButtonState] = useState(true);

    const showDrawer = () => {
        setNavActive(true);
    };

    const onClose = () => {
        setNavActive(false);
    }

    useEffect(() => {
        const dummyData = {
            query: "ping",
        };
        // call dummy for python server to wake up
        fetch("https://portfolio-aviral-server-4aca3333ad50.herokuapp.com/parse", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(dummyData),
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
            });
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setNavActive(window.innerWidth >= 1000);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    function PythonFetch(query) {
        console.log("called python fetch");
        const dataToSend = {
            query: query,
        };
        fetch("https://portfolio-aviral-server-4aca3333ad50.herokuapp.com/parse", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(dataToSend),
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
                const intent = data["intent"];
                const newResponse = {
                    "question": query,
                    "response": responseData[intent]
                }
                setResponseState(newResponse)
                setResponseList([...responseList, newResponse]);
                resetSearch();
            });
    }

    function handleQueryClick(query) {
        PythonFetch(query);
    }

    function handleNavClick(nav) {
        setNavState(nav);
        if (nav) {
            if (nav == "about") {
                PythonFetch("Tell me something about yourself");
            } else if (nav == "work_experience") {
                PythonFetch("Show me your work experience");
            } else if (nav == "projects") {
                PythonFetch("Show me your best projects");
            } else if (nav == "contact") {
                PythonFetch("How can I contact you");
            }
        }
    }

    function handleSubmit(event) {
        if (searchContent === "" || !searchButtonState) {
            return;
        } else {
            event.preventDefault();
            PythonFetch(searchContent);
        }
    }

    function resetSearch() {
        setSearchContent("");
        formRef.current.reset();
        formRef.current.querySelector('.search-input').blur();
    }
    return (
            !modalDisplay &&
            <SearchButtonContext.Provider value={{ searchButtonState, setSearchButtonState }}>
            <div className={navActive ? "content" : "content active"}>
                <div className={navActive ? "sidebar active" : "sidebar"}>
                    <div className="sidebar-title">
                        <h4 className="sidebar-heading">Navigation</h4>
                        <Button className="sidebar-close-button" onClick={() => setNavActive(false)}>
                            <TbLayoutSidebarLeftCollapse size={24} strokeWidth={1} className="sidebar-close-icon" />
                        </Button>
                    </div>
                    <div className="sidebar-body">
                        <h5 style={{ fontWeight: 550, fontSize: "0.75em", lineHeight: "1rem", color: "rgba(142,142,160, 1)" }}>Table of Content</h5>
                        <div className={navState === "" ? "sidebar-item active" : "sidebar-item"}>
                            <a className={navState === "" ? "nav-item active" : "nav-item"} href="/" style={{ textDecoration: 'none' }}>Home</a>
                        </div>
                        <div className={navState === "about" ? "sidebar-item active" : "sidebar-item"}>
                            <button className={navState === "about" ? "nav-item active" : "nav-item"} onClick={() => handleNavClick("about")} style={{ textDecoration: 'none' }}>About Me</button>
                        </div>
                        <div className={navState === "work_experience" ? "sidebar-item active" : "sidebar-item"}>
                            <button className={navState === "work_experience" ? "nav-item active" : "nav-item"} onClick={() => handleNavClick("work_experience")} style={{ textDecoration: 'none' }}>My Work-Experience</button>
                        </div>
                        <div className={navState === "projects" ? "sidebar-item active" : "sidebar-item"}>
                            <button className={navState === "projects" ? "nav-item active" : "nav-item"} onClick={() => handleNavClick("projects")} style={{ textDecoration: 'none' }}>Projects</button>
                        </div>
                        <div className={navState === "contact" ? "sidebar-item active" : "sidebar-item"}>
                            <button className={navState === "contact" ? "nav-item active" : "nav-item"} onClick={() => handleNavClick("contact")} style={{ textDecoration: 'none' }}>Contact</button>
                        </div>
                    </div>
                </div>
                <div className="right-content">
                    <header className="sticky header nav-button">
                        {
                            navActive ?
                                null :
                                <Button className="sidebar-open-button" onClick={showDrawer}
                                    icon={<TbLayoutSidebarLeftExpand size={24} strokeWidth={1} className="sidebar-open-icon" />} />
                        }
                        <span className="header-title">
                            <h1 style={{ fontSize: "20px", marginRight: '5px' }}>AviralGPT</h1>
                            <h2 style={{ fontSize: "20px" }}>1.5</h2>
                        </span>
                    </header>
                    <div className="page-body">
                        {/* {response.question === "" && <Introduction hasVisited={hasVisited} />} */}
                        {response.question === "" ? (<CardsLayout handleQueryClick={handleQueryClick} />) : (<ConversationLayout responseList={responseList} scrollToBottom={scrollToBottom} />)}
                        <div className="anchor" id="anchor">
                            <div className="search container">
                                <form className="search-bar" onSubmit={handleSubmit} ref={formRef}>
                                    {/* <input
                                    className="search-input"
                                    type="text"
                                    placeholder="Ask me anything..."
                                    value={searchContent}
                                    onChange={(event) => setSearchContent(event.target.value)}
                                /> */}
                                    <Input.TextArea
                                        className="search-input"
                                        placeholder="Ask me anything..."
                                        value={searchContent}
                                        onPressEnter={handleSubmit}
                                        disabled={!searchButtonState}
                                        onChange={(event) => setSearchContent(event.target.value)}
                                        autoSize={{ minRows: 1, maxRows: 4 }}
                                    />
                                    <div className={searchContent === "" ? "search-button" : "search-button active"}>
                                        <button
                                            disabled={searchContent === "" ? true : false}
                                            type="submit"
                                            className={searchContent === "" ? "search-icon-button" : "search-icon-button active"}>
                                            <FaArrowUp className="search-icon" />
                                        </button>
                                    </div>
                                </form>
                                <h2 className="query-footer">Designed & Built by <a rel="noreferrer" target="_blank" className="query-footer" href="https://github.com/Aviral-03">Aviral Bhardwaj</a></h2>
                            </div>
                        </div>
                        <div ref={containerRef} />
                    </div>
                </div>
            </div>
        </SearchButtonContext.Provider>
    );

}
