import React, { useState, useEffect, useRef, useContext } from "react";
import ImageImport from "./ImageImport";
import pdf from '../Resume.pdf'
import '../styles/otherStyles.css';
import { SearchButtonContext } from "./HomePage";

export default function ResponseLayout({ parsedAnswer, scrollToBottom }) {
    const [currentAnswer, setCurrentAnswer] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [charIndex, setCharIndex] = useState(0);
    const [isTypingComplete, setIsTypingComplete] = useState(false);
    const [linkDisplay, setLinkDisplay] = useState("");

    const { setSearchButtonState } = useContext(SearchButtonContext);

    function linkParser(link) {
        if (link.includes("github")) {
            setLinkDisplay("GitHub");
        } else if (link.includes("linkedin")) {
            setLinkDisplay("LinkedIn");
        } else if (link.includes("Resume")) {
            setLinkDisplay("Resume");
        } else if (link.includes("mail")) {
            setLinkDisplay("Say Hello!");
        } else {
            setLinkDisplay("Website");
        }
    }

    useEffect(() => {
        scrollToBottom();
    }, [isTypingComplete]);

    useEffect(() => {
        let charInterval;
        setSearchButtonState(false);
        if (currentIndex < parsedAnswer.length) {
            const currentArray = parsedAnswer[currentIndex];
            if (Array.isArray(currentArray)) {
                const listItems = currentArray.map((item, index) => (
                    <li >{item}</li>
                ));
                setCurrentAnswer((prevAnswer) => [
                    ...prevAnswer,
                    <ul >{listItems}</ul>,
                ]);
                setCurrentIndex(currentIndex + 1);
            } else if (typeof currentArray === "object") {
                const image = currentArray.imgSrc.replace("../img/", "");
                const caption = currentArray.website;
                const title = currentArray?.title;
                const description = currentArray?.description;
                setIsTypingComplete(true);
              
                let titleCharIndex = -2;
                let descriptionCharIndex = -1;
              
                charInterval = setInterval(() => {
                  if (title && titleCharIndex < title.length) {
                    setCurrentAnswer((prevAnswer) => [
                      ...prevAnswer,
                      <a
                        style={{
                          color: "white",
                          fontWeight: 800,
                          fontSize: "16px",
                          textDecoration: "none",
                          zIndex: 100,
                        }}
                        href={currentArray.website}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {title[titleCharIndex]}
                      </a>
                    ]);
                    titleCharIndex++;
                  } else if (description && descriptionCharIndex < description.length) {
                    setCurrentAnswer((prevAnswer) => [
                      ...prevAnswer,
                      description[descriptionCharIndex]
                    ]);
                    descriptionCharIndex++;
                  } else if (image !== ""){
                    setIsTypingComplete(false);
                    setCurrentAnswer((prevAnswer) => [
                      ...prevAnswer,
                      <div key={`image-${currentIndex}`}>
                        <ImageImport imagePath={image} href={currentArray.website} />
                      </div>
                    ]);
                    clearInterval(charInterval);
                    setCurrentIndex(currentIndex + 1);
                    titleCharIndex = 0;
                    descriptionCharIndex = 0;
                  } else {
                    setCurrentIndex(currentIndex + 1);
                  }
                }, 20);
            } else if (currentArray.includes("Resume")) {
                setIsTypingComplete(true);
                const title = "Resume"
                charInterval = setInterval(() => {
                    if (charIndex < title.length) {
                        setCurrentAnswer((prevAnswer) => [
                            ...prevAnswer,
                            <a
                                href={pdf}
                                target="_blank"
                                rel="noreferrer"
                                className="resume-link"
                            >
                                {title[charIndex]}
                            </a>
                        ]);
                        setIsTypingComplete(true);
                        setCharIndex(charIndex + 1);
                    } else {
                        setIsTypingComplete(false);
                        clearInterval(charInterval);
                        setCurrentIndex(currentIndex + 1);
                        setCharIndex(0);

                    }
                }, 50)
            } else if (currentArray.includes("https://") || currentArray.includes("@")) {
                setIsTypingComplete(true);
                linkParser(currentArray); 
                charInterval = setInterval(() => {
                    if (charIndex < linkDisplay.length) {
                        setCurrentAnswer((prevAnswer) => [
                            ...prevAnswer,
                            <a
                                style={{
                                    color: "white",
                                    fontWeight: 800,
                                    fontSize: "18px",
                                    textDecoration: "none",
                                    zIndex: 100,
                                }}
                                href={currentArray}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {linkDisplay[charIndex]}
                            </a>,
                        ]);
                        setCharIndex(charIndex + 1);
                    } else {
                        clearInterval(charInterval);
                        setCurrentIndex(currentIndex + 1);
                        setCharIndex(0);
                        setIsTypingComplete(false);
                    }
                }, 50);
            } else {
                setIsTypingComplete(true);
                
                charInterval = setInterval(() => {
                    if (charIndex < currentArray.length) {
                        setCurrentAnswer((prevAnswer) => [
                            ...prevAnswer,
                            currentArray[charIndex],
                        ]);
                        setCharIndex(charIndex + 1);
                    } else {
                        clearInterval(charInterval);
                        setCurrentIndex(currentIndex + 1);
                        setCharIndex(0);
                        setIsTypingComplete(false);
                    }
                }, 20);
            }
        }
        if (currentIndex === parsedAnswer.length) {
            setSearchButtonState(true);
        }
        return () => {
            clearInterval(charInterval);
        };
    }, [currentIndex, charIndex, parsedAnswer, linkDisplay]);

    
    return (
        <div className="typewriter">
            {currentAnswer}
            {isTypingComplete && <span className="response-cursor">|</span>}
        </div>
    );
}
