import React from "react";
import { Button, Card } from 'antd';
import '../styles/HomePage.css';
import { Col, Divider, Row } from "antd";

export default function CardsLayout({ handleQueryClick }) {

    return (
        <div className="card-group">
            <Row gutter={[10, 12]}>
                <Col className="gutter-row" xs={24} sm={12} span={12}>
                    <Button className="query-card" onClick={() => handleQueryClick("Tell me something about yourself")}>
                        <div className="card-body">
                            <div className="query-card-title">Introduce</div>
                            <div className="query-card-description">Tell me something about yourself</div>
                        </div>
                    </Button>
                </Col>
                <Col className="gutter-row" xs={24} sm={12} span={12}>
                    <Button className="query-card" onClick={() => handleQueryClick("Show me your best projects")}>
                        <div className="card-body">
                            <div className="query-card-title">Projects</div>
                            <div className="query-card-description">Show me your best projects</div>
                        </div>
                    </Button>
                </Col>
                <Col className="gutter-row" xs={24} sm={12} span={12}>
                    <Button className="query-card" onClick={() => handleQueryClick("What kind of skills do you possess?")}>
                        <div className="card-body">
                            <div className="query-card-title">Skills</div>
                            <div className="query-card-description">What kind of skills do you possess?</div>
                        </div>
                    </Button>
                </Col>
                <Col className="gutter-row" xs={24} sm={12} span={12}>
                    <Button className="query-card" onClick={() => handleQueryClick("I like your profile, give me your resume")}>
                        <div className="card-body">
                            <div className="query-card-title">My Resume</div>
                            <div className="query-card-description">I like your profile, give me your resume</div>
                        </div>
                    </Button>
                </Col>
            </Row>
        </div >
    )
}