import React, { useRef, useState, useContext } from "react";
import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import img from '../img/avi(edit).png';
import ResponseLayout from "./ResponseLayout";
import '../styles/Response.css';    

export default function ConversationLayout({ responseList, scrollToBottom }) {
    const containerRef = useRef(null);

    // function scrollToBottom() {
    //     console.log("scrolling");   
    //     containerRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    // }

    // Get context from parent component

    return (
        <div className="response-container">
            {responseList.map((response, index) => (
                <ResponseItem responseList={response} scrollToBottom={scrollToBottom}/>
            ))}
            <div className="grow">
                <h5>Footer</h5>
            </div>
        </div>
    );
}

function ResponseItem({ responseList, scrollToBottom }) {
    const question = responseList.question;
    const answer = responseList.response;
    const parsedAnswer = Object.values(answer);
    const [clickCount, setClickCount] = useState(0);

    const handleAvatarClick = () => {
        setClickCount(clickCount + 1);

        if (clickCount === 3) {
            window.location.href = "https://www.youtube.com/watch?v=hdcTmpvDO0I&ab_channel=Boys%26ToysReviews";
        }
    };
    return (
        <div style={{width: "100%"}}>
            {
                parsedAnswer &&
                <div className="response-content">
                    <div className="response-span question">
                        <div className="response-title">
                            <Avatar size={36} src={"https://xsgames.co/randomusers/avatar.php?g=pixel"} />
                            <h5
                                style={{
                                    fontWeight: 400,
                                    fontSize: "1rem",
                                    color: "#ececf1",
                                }}
                            >
                                {question}
                            </h5>
                        </div>
                    </div>
                    <div className="response-span answer">
                        <div className="response-body">
                            <div className="response-message">
                                <a className="avatar-container" onClick={handleAvatarClick}>
                                    <Avatar size={36} src={img} />
                                </a>
                                <div className="response-text-container">
                                    <h5 className="response-text">
                                        <ResponseLayout parsedAnswer={parsedAnswer} scrollToBottom={scrollToBottom}/>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            }
        </div>
    )
}
