import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HashRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import HomePage from './components/HomePage';
function AppRouter() {

  return (
    <HashRouter basename="/">
      <Routes>
        <Route path="/" element={<HomePage />} />
        {/* <Route path="/about" element={<HomePage navRequest={'about'} />} />
        <Route path="/projects" element={<Dummy />} />
        <Route path="/work_experience" element={<Dummy />} />
        <Route path="/contact" element={<Dummy />} /> */}
      </Routes>
    </HashRouter>
  );
}

export default AppRouter;
