import { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { FiMessageSquare } from 'react-icons/fi';
import { MdIntegrationInstructions } from 'react-icons/md';
import { IoIosHappy } from 'react-icons/io';
import HomePage from './components/HomePage';
import './styles/App.css';
import pdf from '../src/Resume.pdf';

function App() {
  const hasVisited = localStorage.getItem('hasVisited');
  const [open, setOpen] = useState(!hasVisited);
   

  const handleCloseModal = () => {
    setOpen(false);
    localStorage.setItem('hasVisited', true);
  }

  useEffect(() => {
    return () => {
      localStorage.removeItem('hasVisitedBefore');
    };
  }, []);

  useEffect(() => {
    const dummyData = {
      query: "ping",
    };
    // call dummy for python server to wake up
    fetch("https://portfolio-aviral-server-4aca3333ad50.herokuapp.com/parse", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(dummyData),
    })
        .then((res) => res.json())
        .then((data) => {
            console.log(data);
        });
  }, []);

  return (
    <div className="App">
      <HomePage modalDisplay={open} hasVisited={hasVisited}/>
      <Modal
        open={open}
        width={450}
        className='popup-modal'
        backgroundColor="black"
        cancelButtonProps={{ style: { display: 'none' } }}
        centered
        maskStyle={{ backgroundColor: 'rgba(86,88,105,.7)' }}
        maskClosable={false}
        okText="Explore Aviral's Portfolio"
        onCancel={handleCloseModal}
        onOk={handleCloseModal}
      >
        <div className="popup-modal-content">
          <span className='modal-title'>
            <h1 className='modal-heading'>Welcome to Aviral Bhardwaj's Portfolio</h1>
            <h4 className='sub-heading'>Tips for getting started</h4>
          </span>
          <div className='divider'></div>
          <div className='modal-body'>
            <div className='feature'>
              <div className='feature-title'>
                <FiMessageSquare style={{ fontSize: "20px", fill: "#00A67D", color: "rgb(0, 166, 125, 0.1)" }} />
                <span style={{ padding: "10px", fontWeight: 550, fontSize: "16px" }}>Get to know me </span>
              </div>
              <span style={{ marginTop: "-10px" }}>Here's my ChatGPT, coded from scratch. Instead of asking general questions, you can ask me about my experiences, projects, skills and much more. Feel free to use starter questions.</span>
            </div>
            <div className='feature'>
              <div className='feature-title'>
                <MdIntegrationInstructions style={{ fontSize: "20px", fill: "rgb(210,146,255)", color: "rgb(210,146,255, 0.5)" }} />
                <span style={{ padding: "10px", fontWeight: 550, fontSize: "16px" }}>Here's my resume!</span>
              </div>
              <span style={{ marginTop: "-10px" }}>If you are on a time-cruch, I understand. Here's my <a href={pdf} target="__blank" rel="noreferrer">resume</a> for quick reference. </span>
            </div>
            <div className='feature'>
              <div className='feature-title'>
                <IoIosHappy style={{ fontSize: "20px", fill: "rgb(198,127,10)", color: "rgbrgb(198,127,10, 0.1)" }} />
                <span style={{ padding: "10px", fontWeight: 550, fontSize: "16px" }}>Thank you for visiting</span>
              </div>
              <span style={{ marginTop: "-10px" }}>Here's me personally thanking you in a <a target="_blank" href='https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley'>video</a></span>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default App;
